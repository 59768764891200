import React from "react";
import '../scss/wallpaper.scss';

function Wallpaper() {

  return(
    <div className="wallpaper">
      <div className="wallpaperimage">
        <img src="assets/ornn.jpg" alt="Ornn" className="mainimage"></img>
      </div>
    </div>
  )
}

export default Wallpaper; 